import { createSlice } from "@reduxjs/toolkit";

export const planSlice = createSlice({
  name: "plans",
  initialState: {
    activePlan: 0,
    refValue: null,
    extraOrderData: null,
  },
  reducers: {
    selectPlan: (state, action) => {
      state.activePlan = action.payload;
    },
    selectValue: (state, action) => {
      state.refValue = action.payload;
    },
    selectOrderData: (state, action) => {
      state.extraOrderData = action.payload;
    },
  },
});

export const { selectPlan } = planSlice.actions;
export const { selectValue } = planSlice.actions;
export const { selectOrderData } = planSlice.actions;

export default planSlice.reducer;
