import { gql } from "@apollo/client";

export const LOAD_SERVICES = gql`
  query MyQuery($lang: String!) {
    services(
      where: { status: { _eq: true }, language: { code: { _eq: $lang } } }
      order_by: { sort_order: asc }
    ) {
      id
      name
      meta_data
      language {
        code
      }
    }
    service_providers(
      order_by: { sort_order: asc }
      where: {
        status: { _eq: true }
        quick_links: { _eq: true }
        language: { code: { _eq: $lang } }
      }
      limit: 8
    ) {
      id
      name
      meta_data
    }
  }
`;

export const LOAD_PROVIDERS = gql`
  query FilteredServiceProviders($id: smallint!, $lang: String!) {
    service_providers(
      where: {
        service_to_service_providers: { service: { id: { _eq: $id } } }
        language: { code: { _eq: $lang } }
        status: { _eq: true }
      }
      order_by: { sort_order: asc }
    ) {
      id
      name
      meta_data
      status
      sort_order
    }
    services(
      where: {
        status: { _eq: true }
        language: { code: { _eq: $lang } }
        id: { _eq: $id }
      }
      order_by: { sort_order: asc }
    ) {
      id
      name
      meta_data
    }
  }
`;
export const PLAN_PROVIDER = gql`
  query FilteredPlans($id: smallint!, $tagValue: String, $lang: String!) {
    plans(
      where: {
        service_provider_id: { _eq: $id }
        tags: { _has_key: $tagValue }
        language: { code: { _eq: $lang } }
        status: { _eq: true }
      }
      order_by: { sort_order: asc }
    ) {
      name
      meta_data
      service_provider_id
      evcode
      amount
      language {
        code
      }
    }
  }
`;

export const SERVICE_PROVIDER = gql`
  query FilteredOneProvider($id: smallint!, $lang: String!) {
    service_providers(
      order_by: { name: asc }
      where: {
        status: { _eq: true }
        language: { code: { _eq: $lang } }
        id: { _eq: $id }
      }
    ) {
      id
      name
      meta_data
      tags
      language {
        code
      }
    }
  }
`;
export const PAYMENT__PROVIDER = gql`
  query getPaymentMethods($lang: String!) {
    payment_methods(
      where: { status: { _eq: true }, language: { code: { _eq: $lang } } }
      order_by: { sort_order: desc }
    ) {
      code
      name
      global_settings
    }
  }
`;
export const ORDER__PROVIDER = gql`
  subscription getOrders($reference: String!, $lang: String!) {
    orders(
      where: {
        reference: { _eq: $reference }
        language: { code: { _eq: $lang } }
      }
    ) {
      id
      meta_data
      items
    }
  }
`;

export const CUSTOMER__ORDERS = gql`
  query getOrders($offset: Int! = 0) {
    orders(
      order_by: { id: desc }
      limit: 10
      offset: $offset
      where: { paid: { _eq: true } }
    ) {
      id
      alpha_id
      totals
      Order_OrderStatus {
        name
      }
      items
      created_at
    }
    orders_aggregate(where: { paid: { _eq: true } }) {
      aggregate {
        count
      }
    }
  }
`;
