import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import  {store, persistor } from './app/Store'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './services/i18n'
import {BrowserRouter as Router} from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
//Fonts
import './fonts/museosans-300.ttf'
import './fonts/museosans-500.ttf'
import './fonts/museosans-700.ttf'

Sentry.init({
  dsn: "https://49432b9ebab14e998d7c3432fc6e465c@o1005436.ingest.sentry.io/5966120",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});


ReactDOM.render(
  <React.StrictMode>
   <React.Suspense fallback={<span className="index_loading"> <img className="loadingImg" src="/images/blueLoader.gif" alt="loading"/></span>}>
   <Provider store={store}>
     <PersistGate persistor={persistor}>
     
     <Router>
        <App />
      </Router>
     </PersistGate>
    </Provider>
   </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
