import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
  ApolloLink,
} from "@apollo/client";
import {
  getMainDefinition,
  offsetLimitPagination,
} from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";
import config from "./index";

export const useConfigClient = () => {
  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors)
        // eslint-disable-next-line array-callback-return
        console.warn("GraphQL Errors: ", graphQLErrors);
      graphQLErrors.forEach(({ message, extensions }) => {
        switch (extensions.code) {
          case "invalid-jwt":
            // refetch the jwt
            const oldHeaders = operation.getContext().headers;
            const token = localStorage.getItem("accessToken");
            operation.setContext({
              headers: {
                ...oldHeaders,
                Authorization: `Bearer ${token}`,
              },
            });
            // retry the request, returning the new observable
            return forward(operation);
            // eslint-disable-next-line no-unreachable
            break;

          default:
            // default case

            // console.log(operation.operationName + extensions.code + message)

            return forward(operation);
        }
      });
      if (networkError) {
        console.log(`[Network error]: ${networkError.message}`);
        window.location.replace("/networkError");
      }
    }
  );

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          orders: offsetLimitPagination(),
        },
      },
    },
  });

  const httpLink = new HttpLink({
    uri: config.GRAPHQL_URL,
    headers: {
      //'X-Hasura-Admin-Secret': '',
      //'Authorization': `Bearer ${authTokenStorage}`,
    },
  });
  const token = localStorage.getItem("accessToken");
  const wsLink = new WebSocketLink({
    uri: config.GRAPHQL_WS,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        },
      },
    },
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const authLink = new ApolloLink((operation, forward) => {
    const operationName = operation.operationName;

    if (
      operationName === "getUser" ||
      operationName === "createOrder" ||
      operationName === "payOrder" ||
      operationName === "getOrders"
    ) {
      const token = localStorage.getItem("accessToken");

      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        },
      });
    }

    return forward(operation);
  });

  // Initialize Apollo Client
  const client = new ApolloClient({
    cache,
    link: errorLink.concat(authLink.concat(link)),
  });

  return client;
};
