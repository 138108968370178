import { IconButton } from '@material-ui/core'
// eslint-disable-next-line no-unused-vars
import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'

import { Link } from 'react-router-dom'
import './HomepageService.css'


function HomepageService({id,image,title,service,changeName}) {
 
  
    return (
       <Link className="homepage__Service" to={ service ? `/service/${id}` : `/servicePlan/${id}` }>
            <div className="homepage__Service_Inner"  >
       
            <IconButton>
              <img src={image} alt="service" className="service__icon" />
            </IconButton>      
        
            <h5>{title}</h5>
          </div>
       </Link>
    )
}

export default HomepageService
