import {combineReducers,createStore} from '@reduxjs/toolkit'
import {persistReducer,persistStore }from 'redux-persist'
import planReducer from '../features/planSlice'
import storage from 'redux-persist/lib/storage'


const reducer = combineReducers({
  plan:planReducer
})


const persistConfig ={
  key:'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig,reducer)

export const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const persistor = persistStore(store);