
import React,{useState} from 'react'
import {  useParams } from 'react-router'
import './MobilePage.css'
import HomepageService from './HomepageService'
import {useQuery} from '@apollo/client'
import {LOAD_PROVIDERS, LOAD_SERVICES} from './Graphql/Queries'
import { useTranslation } from 'react-i18next'
import {  motion } from 'framer-motion'
import Skeleton from '@material-ui/lab/Skeleton'


function MobilePage() {
    const {t} = useTranslation()
   
    const lang = localStorage.getItem("lang")
    const {id} = useParams()
    // eslint-disable-next-line no-unused-vars
    const {data:data1,loading:loading1,error:error1} = useQuery(LOAD_SERVICES,{variables:{lang}})
    const { data, loading, error } = useQuery(LOAD_PROVIDERS,{variables:{id,lang}}) 
    // eslint-disable-next-line no-unused-vars
    const [word,setWord] = useState()
  
    
   if(error || error1){
      console.error(error)
   }

 
    return (
        <motion.div
        variants={{
            hidden:{
               opacity:0,
               x:-70,
            },
            
            visible:(i)=>({
               opacity:1,
               x:1,
               transition:{
                 ease: [0.17, 0.67, 0.83, 0.67],
                 duration:0.15
               }
               
            })
         }}
         
         initial="hidden"
         animate="visible">
                <div className="mobilePage">
                <div className="mobilepage__header">
                     <h2>{data?.services[0].name}</h2>
                
                    <p>{data?.services[0].meta_data.description}</p>
                  
                </div>
              
                {loading && 
                 <div className="mobilepage__provider">
                 {id === 1 && <h4>{t( "select_provider")}</h4>}
                 <div className="mobilepage__prosLoading">
            
                 <Skeleton  animation="wave" width={40} height={40} variant='circle'/>
                 <Skeleton className="moviLoading"  animation="wave" width={40} height={40} variant='circle'/>
                 <Skeleton  animation="wave" width={40} height={40} variant='circle'/>
                 
                
             
                
               
                 </div>
             </div>}
              {data &&
                <div className="homepage__service">
                {id === 1 && <h4>{t( "select_provider")}</h4>}
                <div className="homepageService">
                {data?.service_providers.map(provider=>(
                    provider && provider.meta_data && 
                <HomepageService service={false} key={provider.id} id={provider.id} image={`https://res.cloudinary.com/www-monakapp-com/image/upload/d_null,h_40,w_40,dpr_auto/${provider.meta_data.icon}`} title={provider.name} description={provider.meta_data.description} changeName={heading => setWord(heading)} />
                 
                ))}  
                
               
            
               
              
                </div>
            </div>}
             
            </div>
        </motion.div>

    )
}

export default MobilePage
