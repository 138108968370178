import {
  Button,
  Drawer,
  Divider,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowForwardIos } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import { useHistory, useParams } from "react-router";
import { PLAN_PROVIDER, SERVICE_PROVIDER } from "./Graphql/Queries";
import { useQuery } from "@apollo/client";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { selectPlan, selectOrderData } from "./features/planSlice";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Skeleton from "@material-ui/lab/Skeleton";
import "./Service.css";

const useStyles = makeStyles({
  fullList: {
    width: "100%",
  },
});

function Service() {
  const { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const lang = localStorage.getItem("lang");
  // eslint-disable-next-line no-unused-vars
  const {
    data: data3,
    loading: loading3,
    error: error3,
  } = useQuery(SERVICE_PROVIDER, { variables: { id, lang } });
  const planTag = data3?.service_providers[0].tags[0];
  const [tagValue, setTagValue] = useState();
  const [open, setOpen] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  useEffect(() => {
    setTagValue(planTag);
  }, [planTag]);
  // eslint-disable-next-line no-unused-vars
  const {
    data: data1,
    loading: loading1,
    error: error1,
  } = useQuery(PLAN_PROVIDER, { variables: { id, tagValue, lang } });

  if (error3 || error1) {
    console.error(error3);
  }
  const openSummary = (plan) => {
    if (plan?.evcode.includes("external")) {
      setSelectedPlan(plan);
      setOpen(true);
    } else {
      setSelectedPlan(plan);
      dispatch(selectOrderData({}));
      dispatch(selectPlan({ ...plan }));
      history.push("/summary");
    }
  };

  const handleContinue = () => {
    dispatch(selectOrderData({ phoneNumber }));
    dispatch(selectPlan({ ...selectedPlan }));
    history.push("/summary");
  };

  const handleNumberChange = (event) => {
    if (event.target.value.length > 8) return;
    if (event.target.value.length === 8) {
      if (!validateVodafone(phoneNumber + event.target.value.charAt(7))) {
        setErrorText(t("invalid_number"));
      } else {
        setErrorText("");
      }
    }
    setPhoneNumber(event.target.value);
  };

  const validateVodafone = (phone) => {
    const pattern = /^([7-9]\d{7}|[٧-٩]\d{7})$/;
    const result = pattern.test(phone);
    console.log("Result: ", result);
    return result;
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" ||
      (event.type === "click" && event.target.id.includes("adornment"))
    ) {
      return;
    }
    setPhoneNumber("");
    setErrorText("");
    setOpen(open);
  };

  const panel = () => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      id="adornment-panel"
    >
      <div style={{ padding: "1.25rem" }}>{t("enter_number")}</div>
      <Divider />
      <TextField
        label={t("number")}
        required
        error={errorText.length}
        type="number"
        minLength={8}
        maxLength={8}
        id="standard-adornment-phone"
        style={{ margin: "2rem", width: "80%" }}
        value={phoneNumber}
        onChange={handleNumberChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">+968</InputAdornment>
          ),
        }}
        helperText={errorText}
      />
      <Button
        disabled={phoneNumber.length !== 8 || errorText.length > 0}
        variant="contained"
        color="primary"
        style={{ margin: "2rem", width: "80%" }}
        id="adornment-continue"
        onClick={handleContinue}
      >
        {t("continue")}
      </Button>
    </div>
  );

  return (
    <>
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            x: -70,
          },

          visible: (i) => ({
            opacity: 1,
            x: 1,
            transition: {
              ease: [0.17, 0.67, 0.83, 0.67],
              duration: 0.15,
            },
          }),
        }}
        initial="hidden"
        animate="visible"
      >
        <div className="ooredoo">
          <div className="ooredoo__header">
            <h2>{data3?.service_providers[0].name}</h2>
            <p>{data3?.service_providers[0].meta_data.description}</p>
          </div>
          <Drawer anchor="bottom" open={open} onClose={toggleDrawer(false)}>
            {panel()}
          </Drawer>
          {loading3 && (
            <div className="ooredo__number">
              <div className="ooredoo__loadingbutton">
                <Skeleton duration={1} animation="wave" width={50} key={1} />
                <Skeleton
                  className="loadingButton1"
                  duration={1}
                  animation="wave"
                  width={50}
                  key={2}
                />
                <Skeleton duration={1} animation="wave" width={50} key={3} />
              </div>
            </div>
          )}
          <div className="ooredo__number">
            <div
              className={
                lang === "ar" ? "ooredoo__buttonARA" : "ooredoo__button"
              }
            >
              {data3?.service_providers[0].tags.map((tag) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checkedIcon={
                        <Button
                          className={
                            lang === "ar"
                              ? "oored00__inputARA"
                              : "oored00__input"
                          }
                          variant="contained"
                          color="primary"
                          key={tag}
                        >
                          {tag}
                        </Button>
                      }
                      key={tag}
                      icon={
                        <Button
                          className={
                            lang === "ar"
                              ? "oored00__inputARA"
                              : "oored00__input"
                          }
                          variant="outlined"
                          color="primary"
                        >
                          {tag}
                        </Button>
                      }
                      checked={tagValue === tag}
                    />
                  }
                  onClick={() => setTagValue(tag)}
                />
              ))}
            </div>
          </div>

          {loading1 && (
            <>
              {[1, 2, 3, 4, 5, 6].map((el) => (
                <div className="ooredoo__validity" key={el}>
                  <div className="ooredoo__icco">
                    <div className="ooredoo__data">
                      <Skeleton duration={1} animation="wave" width={180} />
                    </div>
                    <div className="oredo_Loadingvoice">
                      <Skeleton duration={1} animation="wave" width={180} />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}

          {data1?.plans.map((plan) => (
            <div
              className="ooredoo__validity"
              key={plan?.name}
              onClick={() => openSummary(plan)}
            >
              <div className="ooredoo__icco">
                <div className="ooredoo__data">
                  <h2>{plan?.name}</h2>
                </div>
                <div className="oredo_voiice">
                  <h5>{plan.meta_data.description}</h5>
                </div>
              </div>

              <div className="ooredoo__arro">
                <ArrowForwardIos />
              </div>
            </div>
          ))}

          {!loading1 && !data1?.plans.length && (
            <h4 className="no__plan">{t("no_plan")}</h4>
          )}
        </div>
      </motion.div>
    </>
  );
}

export default Service;
