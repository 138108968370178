import { Button } from '@material-ui/core'
import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/client';
import { SERVICE_PROVIDER } from './Graphql/Queries';
import './Failed.css'
function Failed() {
    const {t} = useTranslation()
    const {i18n} = useTranslation();
     const lang= localStorage.getItem("lang")
    useEffect(()=>{
       
        i18n.changeLanguage(lang)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
    const history = useHistory()
    const planActive = useSelector((state) => state.plan.activePlan)
    const id = planActive?.service_provider_id


   
  // eslint-disable-next-line no-unused-vars
    const {data, loading, error} = useQuery(SERVICE_PROVIDER,{variables:{id,lang}})

    useEffect(() => {
            const makeFailed = () => {
                if(localStorage.getItem("failed")===true){
                    history.replace('/')
                }else{
                    localStorage.setItem("failed", true)
                }
            }
            makeFailed()
    },[history]);

    const handleFail=()=>{
       history.push('/summary',{failed: true})
    }

   
    return (
       <motion.div
       variants={{
        hidden:{
           opacity:0,
           x:-70,
        },
        
        visible:(i)=>({
           opacity:1,
           x:1,
           transition:{
             ease: [0.17, 0.67, 0.83, 0.67],
             duration:0.15
           }
           
        })
     }}
     
     initial="hidden"
     animate="visible">
            {data?.service_providers[0].meta_data.icon && <div className="failed">
            <div className="failed__header">
                <h2>{t("fail")}</h2>                
            </div>
            <div className="failed__body">
                <img src="/images/close 1.svg" alt="close" className="failed__image"/>
                <p>{t("recharge_failed")}</p>
                <p>{t("no_back")}</p>
                <img  src={`https://res.cloudinary.com/www-monakapp-com/image/upload/d_null,h_40,w_40,dpr_auto/${data?.service_providers[0].meta_data.icon}`} alt="provider"/>
                <Button variant="outlined" color="primary" onClick={handleFail}>{t("try_again")}</Button>
            </div>
        </div>}
       </motion.div>
    )
}

export default Failed
