import { useQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CUSTOMER__ORDERS } from "./Graphql/Queries";

import "./Orders.css";
function Orders() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const { data, loading, error, fetchMore } = useQuery(CUSTOMER__ORDERS, {
    variables: { offset: 0 },
  });
  useEffect(() => {
    const lang = localStorage.getItem("lang");
    i18n.changeLanguage(lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (error) {
    console.error(error);
  }

  return (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
          x: -70,
        },

        visible: (i) => ({
          opacity: 1,
          x: 1,
          transition: {
            ease: [0.17, 0.67, 0.83, 0.67],
            duration: 0.15,
          },
        }),
      }}
      initial="hidden"
      animate="visible"
    >
      <div className="orders">
        <div className="orders__header">
          <h2>{t("orders")}</h2>
        </div>
        <>
          <div className="orders__top">
            <p>{t("list_of_orders")}</p>
          </div>

          {loading && (
            <>
              <div className="orders__container">
                <div className="orders__containerHeading">
                  <Skeleton duration={1} animation="wave" width={180} />
                </div>
                <div className="orders__containerPara">
                  <Skeleton duration={1} animation="wave" width={180} />
                </div>
              </div>
              <div className="orders__container">
                <div className="orders__containerHeading">
                  <Skeleton duration={1} animation="wave" width={180} />
                </div>
                <div className="orders__containerPara">
                  <Skeleton duration={1} animation="wave" width={180} />
                </div>
              </div>
              <div className="orders__container">
                <div className="orders__containerHeading">
                  <Skeleton duration={1} animation="wave" width={180} />
                </div>
                <div className="orders__containerPara">
                  <Skeleton duration={1} animation="wave" width={180} />
                </div>
              </div>
              <div className="orders__container">
                <div className="orders__containerHeading">
                  <Skeleton duration={1} animation="wave" width={180} />
                </div>
                <div className="orders__containerPara">
                  <Skeleton duration={1} animation="wave" width={180} />
                </div>
              </div>
            </>
          )}

          {data?.orders?.map((order) => (
            <div key={order.id} className="orders__container">
              <div className="orders__containerHeading">
                <img
                  src={`https://res.cloudinary.com/www-monakapp-com/image/upload/d_null,h_40,w_40,dpr_auto/${order?.items?.icon}`}
                  alt={order?.id}
                />
                <p>#{order.id}</p>
              </div>
              <div className="orders__containerHeading">
                <h2>{order.items.name}</h2>
              </div>
              <div className="orders__containerPara">
                <p>
                  {new Date(order?.created_at)
                    .toDateString()
                    .split(" ")
                    .slice(1)
                    .join(" ")}
                </p>
                <h5>
                  {t("recharge_code")} {order.items.pin}
                </h5>
                <h5>{order.items.instruction}</h5>
              </div>
            </div>
          ))}

          {!loading && !data?.orders?.length ? (
            <h4 className="no__orders">{t("No_orders")}</h4>
          ) : (
            <Button
              className="order_button"
              color="primary"
              variant="contained"
              onClick={() =>
                fetchMore({ variables: { offset: data?.orders?.length } })
              }
            >
              {t("load_more")}
            </Button>
          )}
        </>
      </div>
    </motion.div>
  );
}

export default Orders;
