
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import './App.css';
import Failed from './Failed';
import HomePage from './HomePage';
import MobilePage from './MobilePage';
import Service from './Service'
import Success from './Success';
import Summary from './Summary';
import Processing from './Processing';
import Orders from './Orders';

import { useConfigClient } from './config/apolloConfig';
import { AnimatePresence } from 'framer-motion';
import NotFound from './NotFound';
import NetworkError from './NetworkError';
import SomethingWentWrong from './SomethingWentWrong';


function App() {


  const lang = localStorage.getItem("lang")


  const history = useHistory()


  return (
    <ApolloProvider client={useConfigClient()}>
      <div className={lang === 'ar' ? 'App__arabic' : 'App'}>
        <Router history={history}>
          <AnimatePresence>
            <Switch>
              <Route path='/orders'>
                <Orders />
              </Route>
              <Route path='/processing'>
                <Processing />
              </Route>
              <Route path='/failed'>
                <Failed />
              </Route>
              <Route path='/success'>
                <Success />
              </Route>
              <Route path='/summary'>
                <Summary />
              </Route>
              <Route path="/servicePlan/:id">
                <Service />
              </Route>
              <Route path='/service/:id'>
                <MobilePage />
              </Route>
              <Route exact path='/'>
                <HomePage />
              </Route>
              <Route path='/somethingWentWrong'>
                <SomethingWentWrong />
              </Route>
              <Route path='/networkError'>
                <NetworkError />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </AnimatePresence>
        </Router>
      </div>
    </ApolloProvider>
  );
}

export default App;
