import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { PAYMENT__PROVIDER } from "./Graphql/Queries";
import { useDispatch, useSelector } from "react-redux";
import { CREATE__ORDER, PAY_ORDER } from "./Graphql/Mutations";
import { uuid } from "uuidv4";
import "./Processing.css";
import { selectValue } from "./features/planSlice";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

function Processing() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  var [payment, setPayment] = useState();
  // eslint-disable-next-line no-unused-vars
  const [order, setOrder] = useState();
  const [payOrder] = useMutation(PAY_ORDER);
  const [createOrder] = useMutation(CREATE__ORDER);
  const planActive = useSelector((state) => state.plan.activePlan);
  const extraOrderData = useSelector((state) => state.plan.extraOrderData);
  // eslint-disable-next-line no-unused-vars
  const id = planActive?.service_provider_id;

  const lang = localStorage.getItem("lang");
  // eslint-disable-next-line no-unused-vars
  var {
    data: data2,
    loading: loading2,
    error: error2,
  } = useQuery(PAYMENT__PROVIDER, { variables: { lang } });
  const dispatch = useDispatch();
  useEffect(() => {
    i18n.changeLanguage(lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const creatingOrder = async (evcode, ref, lang) => {
      try {
        const { data } = await createOrder({
          variables: { evcode, ref, lang, extraOrderData },
        });
        setOrder({ ...data });
        await getOrder(ref);
        return true;
      } catch (e) {
        return false;
      }
    };
    const ref = uuid();
    creatingOrder(planActive?.evcode, ref, lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrder = async (ref) => {
    try {
      const { data } = await payOrder({
        variables: { ref },
      });
      dispatch(selectValue(ref));
      setPayment({ ...data });
      return true;
    } catch (e) {
      return false;
    }
  };

  if (payment?.payOrder.data) {
    window.location.assign(payment.payOrder.data);
  }

  return (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
          x: -70,
        },

        visible: (i) => ({
          opacity: 1,
          x: 1,
          transition: {
            ease: [0.17, 0.67, 0.83, 0.67],
            duration: 0.15,
          },
        }),
      }}
      initial="hidden"
      animate="visible"
    >
      <div className="processing">
        <div className="processing__header">
          <h2>{t("proces_to")}</h2>
        </div>
        <div className="processing__body">
          <img
            className="loadingImg"
            src="/images/blueLoader.gif"
            alt="loading"
          />
          <p>{t("proces_to")}</p>
          <p>{t("redirect_to_payment")}</p>
          <p>{t("dont_press")}</p>
        </div>
      </div>
    </motion.div>
  );
}

export default Processing;
