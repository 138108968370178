import React from 'react'
import { Link } from 'react-router-dom'
import './NetworkError.css'

function NetworkError() {
    return (
        <div className="NetworkError">
        <div>
        <h4>Some Network Error occured</h4>   
          <h4>The server may be at fault</h4>
          <Link className="linkToHome" to='/'>Try Again</Link>
        </div>
        </div>
    )
}

export default NetworkError
