import {  useQuery, useSubscription } from '@apollo/client'
import { Button } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory} from 'react-router'
import { ORDER__PROVIDER, SERVICE_PROVIDER } from './Graphql/Queries'
import './Success.css'

function Success() {
    const {t} = useTranslation()
    const {i18n} = useTranslation();
    const lang = localStorage.getItem("lang")
    useEffect(()=>{
       
        i18n.changeLanguage(lang)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
    
    const history = useHistory()
    const reference = useSelector((state) => state.plan.refValue)
    const serviceProvider  = useSelector((state)=> state.plan.activePlan)
    const id = serviceProvider.service_provider_id
    const {data,loading,error}= useSubscription(ORDER__PROVIDER,{variables:{reference,lang}})
    // eslint-disable-next-line no-unused-vars
    const{data:data1,loading:loading1,error:error1} = useQuery(SERVICE_PROVIDER,{variables:{id,lang}})
    const handleSuccess =()=>{
      history.push('/')     
    }

    const handleOrders =()=>{
        history.push('/orders')     
      }

    const [timerStopped, setTimer] = useState(false)
    
    const longTimer =  () => { 

        setTimeout(function(){	
            setTimer(true)},10000);
        }

    useEffect(
        () => {
            longTimer()
        },
        []
    );

    if(loading){
        return(
           <span>{t("loading")}</span>
        )
    }
    if(error){
       console.error(error)
    }

  
   
    return (
       <motion.div
       variants={{
        hidden:{
           opacity:0,
           x:-70,
        },
        
        visible:(i)=>({
           opacity:1,
           x:1,
           transition:{
             ease: [0.17, 0.67, 0.83, 0.67],
             duration:0.15
           }
           
        })
     }}
     
     initial="hidden"
     animate="visible"  >
            <div className="success">
            {data?.orders[0]?.meta_data == null ? (
           <>
           <div className="success__loaderHeader">
           <h2>{t("suc")}</h2>
               </div>
           <div className="success__loaderbody">  
            <img className="loadingImg" src="/images/blueLoader.gif" alt="loading"/>
            <p className={timerStopped ? 'inactive' : 'active'}>{t("order_placed")}</p>
            <p className={timerStopped ? 'inactive' : 'active'}>{t("wait_for_code")}</p>
            <p className={timerStopped ? 'inactive' : 'active'}>{t("no_back")}</p>
            <p className={!timerStopped ? 'inactive' : 'active'}>{t("taking_time")}</p>
            <p className={!timerStopped ? 'inactive' : 'active'}>{t("recharge_my_orders")}</p>
            <p className={!timerStopped ? 'inactive' : 'active'}>{t("refund")}</p>
            <p className={!timerStopped ? 'inactive' : 'active'}>{t("come_back")}</p>
            {timerStopped && <Button style={{'marginTop': '15px'}} variant="outlined" color="primary" onClick={handleOrders}>{t("go_to_orders")}</Button>}
           </div>
           </>
            ):(
                <>
                <div className="success__header">
                <h2>{t("suc")}</h2>
            </div>
            <div className="success__body">
                <CheckCircle/>
                <p>{t("recharge_succesful")}</p>
                <p>{t("recharge_code")}{data?.orders[0].items.pin}</p>
                <p>{data?.orders[0].items.instruction}</p>
                <img src={`https://res.cloudinary.com/www-monakapp-com/image/upload/d_null,h_40,w_40,dpr_auto/${data1?.service_providers[0].meta_data.icon}`} alt={data1?.service_providers[0].name}/>
                <Button variant="outlined" color="primary" onClick={handleSuccess}>{t("continue")}</Button>
            </div>
            </>
            )}
           
        </div>
       </motion.div>
    )
}

export default Success
