import React, { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import "./Summary.css";
import { CheckCircle } from "@material-ui/icons";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { PAYMENT__PROVIDER, SERVICE_PROVIDER } from "./Graphql/Queries";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

function Summary(props) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const planActive = useSelector((state) => state.plan.activePlan);
  const orderData = useSelector((state) => state.plan.extraOrderData);
  const id = planActive?.service_provider_id;

  const lang = localStorage.getItem("lang");
  // eslint-disable-next-line no-unused-vars
  const {
    data: data1,
    loading: loading1,
    error: error1,
  } = useQuery(SERVICE_PROVIDER, { variables: { id, lang } });
  // eslint-disable-next-line no-unused-vars
  var {
    data: data2,
    loading: loading2,
    error: error2,
  } = useQuery(PAYMENT__PROVIDER, { variables: { lang } });

  const history = useHistory();

  const [active, setActive] = useState("suc");
  useEffect(() => {
    const lang = localStorage.getItem("lang");
    i18n.changeLanguage(lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // convert object to string and store in localStorage
    function saveToLocalStorage(planActive) {
      try {
        const serialisedState = JSON.stringify(planActive);
        localStorage.setItem("persistantState", serialisedState);
      } catch (e) {
        console.warn(e);
      }
    }
    saveToLocalStorage(planActive);

    function loadFromLocalStorage() {
      try {
        const serialisedState = localStorage.getItem("persistantState");
        if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState);
      } catch (e) {
        console.warn(e);
        return undefined;
      }
    }
    loadFromLocalStorage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlan = () => {
    history.replace(`/servicePlan/${id}`);
  };
  const handleProceed = () => {
    history.push("/processing");
  };

  if (error1 || error2) {
    console.error(error2);
  }
  return (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
          x: -70,
        },

        visible: (i) => ({
          opacity: 1,
          x: 1,
          transition: {
            ease: [0.17, 0.67, 0.83, 0.67],
            duration: 0.15,
          },
        }),
      }}
      initial="hidden"
      animate="visible"
    >
      <div className="summary">
        <div className="summary__header">
          <h2>{t("summary")}</h2>
        </div>
        {loading1 ? (
          <span className="summary_loading">
            <img
              className="loadingImg"
              src="/images/blueLoader.gif"
              alt="loading"
            />
          </span>
        ) : (
          <>
            <div className="summary__dis">
              <div className="summary__body">
                <img
                  src={`https://res.cloudinary.com/www-monakapp-com/image/upload/d_null,h_40,w_40,dpr_auto/${data1?.service_providers[0].meta_data.icon}`}
                  alt={data1?.service_providers[0].name}
                />
                <h5>
                  {t("other_data")}
                  {orderData.phoneNumber}
                </h5>
              </div>
              <div className="summary__validity">
                <div className="ooredoo__icco">
                  <div
                    className={`summary__dataa ${
                      lang === "ar" && "summary__Ardata"
                    }`}
                  >
                    <h2>{planActive?.name}</h2>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handlePlan}
                    >
                      {t("change_plan")}
                    </Button>
                  </div>
                  <div className="oredo_voice">
                    <h5>{planActive?.meta_data?.description}</h5>
                  </div>
                </div>
              </div>
            </div>
            <hr className="summary__pay" />
            <div className="summary__card">
              <div className="summaryPayment" onClick={() => setActive("suc")}>
                <img
                  className="summaryPayment__img"
                  alt="card"
                  src={`https://res.cloudinary.com/www-monakapp-com/image/upload/d_null,w_25,dpr_auto/${data2?.payment_methods[0].global_settings.metadata.icon}`}
                />
                <div
                  className={
                    lang === "ar" ? "summaryArPara" : "summary__paraHed"
                  }
                >
                  <h5>{data2?.payment_methods[0].name}</h5>
                  <p>
                    {
                      data2?.payment_methods[0].global_settings.metadata
                        .subHeading
                    }
                  </p>
                </div>
                <FormControlLabel
                  className="summaary___input"
                  control={
                    <Checkbox
                      checkedIcon={<CheckCircle />}
                      icon={<CheckCircle />}
                      checked={active === "suc"}
                      value="suc"
                    />
                  }
                />
              </div>
            </div>
            <div>
              <div className="summary__proceed">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleProceed}
                >
                  {t("proceed_to_pay")}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </motion.div>
  );
}

export default Summary;
