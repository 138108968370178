import { ArrowForwardIos } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation} from '@apollo/client'
import {LOAD_SERVICES} from './Graphql/Queries'
import './HomePage.css'
import HomepageService from './HomepageService'
import { USER_MUTATION } from './Graphql/Mutations'
import { useHistory, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import {  motion } from 'framer-motion'
import Skeleton from '@material-ui/lab/Skeleton'

function HomePage() {
   const {t} = useTranslation();
   const {i18n} = useTranslation();
   var [tokenStatus,setTokenStatus] = useState('')
   var [status,setStatus] = useState()
   const history = useHistory()
   const search = useLocation().search
   const tokenValue = new URLSearchParams(search).get('token') ? new URLSearchParams(search).get('token') : (localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : null)
   const lang = new URLSearchParams(search).get('lang') ? new URLSearchParams(search).get('lang') : (localStorage.getItem("lang") ? localStorage.getItem("lang") : "en")
   const [getUser] = useMutation(USER_MUTATION)
   const [ getServices, {loading: loading1, data: data1 }] = useLazyQuery(LOAD_SERVICES)
   localStorage.setItem("failed", false)
   // eslint-disable-next-line no-unused-vars
   const [word,setWord] = useState()

   useEffect(()=>{

      if(tokenValue === null || tokenValue === undefined || status === 401 ){
       }else{
         try {  
            localStorage.setItem("accessToken", tokenValue);
          } catch (e) {
            console.warn(e);
          }

         const getUserInfo = async tokenValue => {
            try{
               const {data} = await getUser({
                  variables: {tokenValue},
               })
              setStatus(data?.getUser.status)
              setTokenStatus(data?.getUser?.data?.user?.firstName)
              if(status === 200){
               return true
              }             
             return false
            }catch(e) {
               console.warn(e)
             return false
            }
          }
            getUserInfo(tokenValue); 
       }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ getUser])


    useEffect(()=>{

         localStorage.setItem("lang",lang)
         i18n.changeLanguage(lang)
         getServices({ variables: { lang } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


 const openOrders =()=>{
    history.push('/orders')
 }
    return (
       <motion.div
       variants={{
         hidden:{
            opacity:0,
            x:-70,
         },
         
         visible:(i)=>({
            opacity:1,
            x:1,
            transition:{
              ease: [0.17, 0.67, 0.83, 0.67],
              duration:0.15
            }
            
         })
      }}
      
      initial="hidden"
      animate="visible">
            <div className="homepage">
            <div className="homepage__header">
            <div className="homepage__title">
                 {<h2>{t("hello" , { tokenStatus })}</h2>}
                 {<p>{t("please_select")}</p>}
             </div>
             <div className="homepage__icon">
                <img src="/images/Logo.svg" alt="logo"/>
             </div>
            </div>
            
           <>
           {loading1 && 
           <div className="homepageLoading__service">
           <h4>{t("select_service")}</h4>
          <div className="homepageServiceLoading">
           <Skeleton className="loadingIcon"  animation="wave" width={40} height={40} variant='circle'/>
           <Skeleton className="loadingIcon" animation="wave" width={40} height={40} variant='circle'/>
           <Skeleton className="loadingIcon" animation="wave" width={40} height={40} variant='circle'/>
           <Skeleton className="loadingIcon" animation="wave" width={40} height={40} variant='circle'/>
           <Skeleton className="loadingIcon" animation="wave" width={40} height={40} variant='circle'/>
           <Skeleton className="loadingIcon" animation="wave" width={40} height={40} variant='circle'/>
           <Skeleton className="loadingIcon" animation="wave" width={40} height={40} variant='circle'/>
           <Skeleton className="loadingIcon" animation="wave" width={40} height={40} variant='circle'/>
           </div>
         
       </div>
           }
           {data1 &&  <div className="homepage__service">
                <h4>{t("select_service")}</h4>
               <div className="homepageService">
              { data1?.services.map(service=>(
                  service && service.meta_data &&  
                     <HomepageService service={true} key={service.id} id={service.id} image={`https://res.cloudinary.com/www-monakapp-com/image/upload/d_null,h_34,w_34,dpr_auto/${service.meta_data.icon}`} description={service.meta_data.description} title={service.name} />
               ))}
              
               </div>
            </div>}
          {data1 &&   <div className="homepage__orders" onClick={openOrders}>
               <h4>{t("orders")}</h4>
               <ArrowForwardIos className="homepage_orderIcon"/>
            </div>}
            {loading1 && 
           <div className="homepageLoading__service">
           <h4>{t("quick_links")}</h4>
          <div className="homepageServiceLoading">
           <Skeleton className="loadingIcon"  animation="wave" width={40} height={40} variant='circle'/>
           <Skeleton className="loadingIcon" animation="wave" width={40} height={40} variant='circle'/>
           <Skeleton className="loadingIcon" animation="wave" width={40} height={40} variant='circle'/>
           <Skeleton className="loadingIcon" animation="wave" width={40} height={40} variant='circle'/>
           <Skeleton className="loadingIcon" animation="wave" width={40} height={40} variant='circle'/>
           <Skeleton className="loadingIcon" animation="wave" width={40} height={40} variant='circle'/>
           <Skeleton className="loadingIcon" animation="wave" width={40} height={40} variant='circle'/>
           <Skeleton className="loadingIcon" animation="wave" width={40} height={40} variant='circle'/>
           </div>
         
       </div>
           }
         {data1 &&  <div className="homepage__service">
                <h4>{t("quick_links")}</h4>
               <div className="homepageService">
                 
                  {data1?.service_providers.map(provider=>(
                     provider && provider.meta_data && 
                        <HomepageService name subtitle service={false} key={provider.id} id={provider.id} image={`https://res.cloudinary.com/www-monakapp-com/image/upload/d_null,h_34,w_34,dpr_auto/${provider.meta_data.icon}`} description={provider.meta_data.description} title={provider.name} changeName={heading => setWord(heading)}/>
                 ))}
                 
               </div>
            </div>}
            </>
          
         </div>
            
       </motion.div>
       
          )
}

export default HomePage
