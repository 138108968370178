import React from 'react'
import { Link } from 'react-router-dom'
import './NotFound.css'

function NotFound() {
    return (
        <div className="notFound">
            <div>
            <h2>404</h2>
            <h5>Looks like the page you're looking for has been removed or doesn't exist</h5>
            <Link to='/'> Return to Home Page</Link>
                </div>
        </div>
    )
}

export default NotFound
