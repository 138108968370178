import React from 'react'
import './SomethingWentWrong.css'

function SomethingWentWrong() {
    return (
        <div className="somethingWentWrong">
            <h4>Something Went Wrong</h4>
        </div>
    )
}

export default SomethingWentWrong
